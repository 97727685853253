export const Routes = {
  HOME: '/',
  DOCS: 'docs',
  STATUS: 'status',
  LOGIN: 'auth/signin',
  AFTER_LOGIN: 'rankings/stocks/cannabis',
} as const;

export type Route = (typeof Routes)[keyof typeof Routes];
export const ROUTES: string[] = Object.values(Routes);

export function getFinalPath(route: Route) {
  const index = route.lastIndexOf('/');
  if (index === -1) {
    return route;
  }
  return route.slice(index + 1);
}

export function routeToUrl(route: Route) {
  if (route === Routes.HOME) {
    return route;
  }
  return `/${route}`;
}
