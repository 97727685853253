import { useRouter } from 'next/compat/router';
import Head from 'next/head';
import Script from 'next/script';
import { IS_PRODUCTION } from '~/modules/config';

export interface MetaProps {
  title?: string;
  description?: string;
  siteName?: string;
  image?: string;
  domain?: string;
  twitterHandle?: string;
}

// Meta values that are the same across all pages
const globalMeta = {
  // Site name
  siteName: 'Stock Rankings',
  // Your production domain (example: https://myapp.com)
  domain: 'https://stock-rankings.com',
  // Your Twitter handle (example: @divjoy)
  twitterHandle: '',
};

// Default meta values for current page (override with props)
export const defaultPageMeta = {
  // Page title
  title: globalMeta.siteName,
  // Page description
  description: 'We rank the top stocks based on their financials, performance and valuation.',
  // Social share image (create this file in /public/images/)
  image: '/images/logos/white_logo.png',
  // Page type (see https://ogp.me/#types)
  type: 'website',
};

export function Meta(props: MetaProps & { children?: React.ReactNode }) {
  const { children, ...customPageMeta } = props;
  const router = useRouter();

  // Construct meta object from global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  // Note: Each tag should have a unique `key` so that they are de-deduped if other
  // `Meta` components are rendered on the same page or within nested components.
  // prettier-ignore
  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />

        <title>{meta.title}</title>
        <meta content={meta.description} name="description" key="description" />
        {meta.domain && <link rel="canonical" href={`${meta.domain}${router?.asPath || ''}`} key="canonical" />}

        {/* Open Graph */}
        <meta property="og:title" content={meta.title} key="og-title" />
        <meta property="og:description" content={meta.description} key="og-description" />
        <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
        <meta property="og:type" content="website" key="og-type" />
        {meta.domain && <meta property="og:url" content={`${meta.domain}${router?.asPath || ''}`} key="og-url" />}
        {meta.domain && meta.image && <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />}

        {/* Twitter */}
        <meta name="twitter:title" content={meta.title} key="twitter-title" />
        <meta name="twitter:description" content={meta.description} key="twitter-description" />
        <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
        {meta.twitterHandle && <meta name="twitter:site" content={meta.twitterHandle} key="twitter-site" />}
        {meta.domain && meta.image && <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />}

        {IS_PRODUCTION && (
              <Script
                defer
                src="https://analytics.sambatista.com/script.js"
                data-website-id="17103b1e-c428-4fd1-abfe-53f74adc14b9"
              />
            )}
      </Head>
      {children}
    </>
  );
}
