import '~/modules/utilities/load_client_config'; // must be the first import
// prettier-ignore
import axios from 'axios';
import { DeepReadonly } from 'ts-essentials';

declare global {
  var clientConfig: DeepReadonly<
    ReturnType<typeof import('~/modules/utilities/build_bundled_config').buildBundledConfig>
  >;
}

// Schema configs
const _API_VERSIONS = ['1', '2'] as const;
export const API_VERSIONS = _API_VERSIONS as unknown as string[];
export type ApiVersion = (typeof _API_VERSIONS)[number];

const _SCHEMA_VERSIONS = [1, 2] as const;
export const SCHEMA_VERSIONS = _SCHEMA_VERSIONS as unknown as SchemaVersion[];
export type SchemaVersion = (typeof _SCHEMA_VERSIONS)[number];

export const API_VERSION: ApiVersion = '1';
export const SCHEMA_VERSION: SchemaVersion = 1;
export const NEXT_SCHEMA_VERSION: SchemaVersion = 2;
export const SCHEMA_VERSION_HEADER = 'SchemaVersion';

// Client configs (injected by the server, is a result of calling build_bundled_config.ts)
export const VERSION = clientConfig.VERSION;
export const MODE = clientConfig.MODE;
export const WS_URL = clientConfig.WS_URL;
export const WS_LOGGER_SOURCE = clientConfig.WS_LOGGER_SOURCE;
export const PUBLIC_URL = clientConfig.PUBLIC_URL;
export const API_URL = clientConfig.API_URL;
export const CDN_URL = clientConfig.CDN_URL;
export const DEFAULT_LANGUAGE = clientConfig.DEFAULT_LANGUAGE;
export const ENABLE_AXIOS_CREDENTIALS = clientConfig.ENABLE_AXIOS_CREDENTIALS;

if (ENABLE_AXIOS_CREDENTIALS) {
  // Enable cookie passing to API requests
  axios.defaults.withCredentials = true;
}

// Public NextJS client keys
export const SHEETS_ENDPOINT = clientConfig.SHEETS_ENDPOINT;
export const SHEETS_TAB_ID = clientConfig.SHEETS_TAB_ID;
export const STRIPE_PRICE_BUSINESS = clientConfig.STRIPE_PRICE_BUSINESS;
export const STRIPE_PRICE_PRO = clientConfig.STRIPE_PRICE_PRO;
export const STRIPE_PRICE_STARTER = clientConfig.STRIPE_PRICE_STARTER;
export const STRIPE_PUBLISHABLE_KEY = clientConfig.STRIPE_PUBLISHABLE_KEY;

// Unleash FeatureFlags
export const FEATURE_FLAG_URL = clientConfig.FEATURE_FLAG_URL;
export const FEATURE_FLAG_CLIENT_KEY = clientConfig.FEATURE_FLAG_CLIENT_KEY;
export const FEATURE_FLAG_APP_NAME = clientConfig.FEATURE_FLAG_APP_NAME;
export const FEATURE_FLAG_REFRESH_INTERVAL = clientConfig.FEATURE_FLAG_REFRESH_INTERVAL;

// Computed configs
export const IS_DEVELOPMENT = clientConfig.MODE === 'development';
export const IS_PRODUCTION = clientConfig.MODE === 'production';
