import axios from 'axios';
import { UserSession } from '~/dtos/user_session';
import { API_URL } from '~/modules/config';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '~/modules/constants';
import { selectData } from '~/modules/utilities/axios_utils';

export function authenticateUserSession() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? '';
  return axios
    .get(`${API_URL}/auth`, {
      headers: {
        Authorization: `Bearer ${accessToken},${refreshToken}`,
      },
    })
    .catch(async function attemptRefresh() {
      return axios.get(`${API_URL}/auth/refresh`);
    })
    .then(selectData)
    .then(persistUserSession);
}

export function logoutUser() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? '';
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  return axios.post(`${API_URL}/auth/logout`, {
    headers: {
      Authorization: `Bearer ${accessToken},${refreshToken}`,
    },
  });
}

export function persistUserSession(userSession: UserSession) {
  const accessToken = userSession.accessToken || localStorage.getItem(ACCESS_TOKEN_KEY) || '';
  const refreshToken = userSession.refreshToken || localStorage.getItem(REFRESH_TOKEN_KEY) || '';
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  return userSession;
}
